import { GlobalStyle } from "../../common/styles/global.js";

export const YouLost = () => {
  return (
    <>
      <GlobalStyle />
      <div className="main">
        <div id="mainbox">
          <div id="home___tool-bar">
            <div id="home___tool-bar-name">
              <div className="home___namebar-fill"></div>
              <div id="home___namebar">
                <a
                  href="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Marcellus
                </a>
              </div>
              <div className="home___namebar-fill"></div>
            </div>
            <div id="home___tool-bar-tiles">
              <div id="home___tool-bar___entries">
                <a
                  className="home___tool-bar-button"
                  href="https://github.com/c3llus"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">GitHub</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://www.linkedin.com/in/c3llus/"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">LinkedIn</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://blog.c3llus.dev"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">Blog</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <img
            src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExdDM0MXphcGJhNmxieXB3ZzZheDBic2c1NDVoamlsYXZhangxejdqNCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/B4uP3h97Hi2UaqS0E3/giphy.gif"
            alt="You Lost"
            width="50%"
            className="center"
          />
        </div>
      </div>
    </>
  );
};
