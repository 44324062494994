import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import { Home } from '../pages/homepage/home.js';
// import { About } from '../pages/about/about.js';
import { Projects } from '../pages/projects/projects.js';
import { YouLost } from '../pages/youlost/youlost.js';

export const Router = () => {
  return (
    <BrowserRouter>
        <Routes>
            {/* Static pages routes */}
            <Route path={"/"} element={<Home/>} />
            <Route path={"/projects"} element={<Projects/>} />

            {/* Error routes */}
            <Route path={"*"} element={<YouLost/>} /> {/* TODO: you lost */}
        </Routes>
    </BrowserRouter>
  );
}