import { createGlobalStyle } from "styled-components";



export const GlobalStyle = createGlobalStyle`
  :root {
    --white: #ffffff;
    --licorice: #000000;
    --blue-green: #2ec4b6;
    --redical: #ff3366;
    --florange: #e4572e;
    --gray: #d2cecd;
    --window: #3a6ea5;
  }

  body {
    margin: 0;
    overflow: hidden;
    background-color: var(--window);
  }

  .main {
    width: 100%;
    height: 100vh;
    z-index: 400;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: #333;
    /* background-image: url('https://media.giphy.com/media/L7Lm7JSWSRkW5Vvjjg/giphy.gif'); */
  }

  #mainbox {
    background-color: var(--gray);
    height: 70%;
    width: 50%;
    min-width: 500px;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    padding-top: 1px;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    box-shadow: 10px 10px 5px hsl(55, 64%, 8%);
  }
  
  .mainbox-text {
    font-family: "IBM Plex Mono", monospace;
    font-size: 2vh;
    color: var(--licorice);
  }

  @media only screen and (max-device-width: 480px) {
    #mainbox {
      background-color: var(--white);
      height: 80%;
      width: 95%;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      padding-top: 1px;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: auto;
      box-shadow: 20px 20px 10px #0a3b76;
    }

    .mainbox-text {
      font-size: 2vh;
    }
  }

  @media only screen and (min-width: 1900px) {
    #mainbox {
      height: 50%;
      width: 50%;
      min-width: 500px;
      max-width: 800px;
    }
    .mainbox-text {
      font-size: 1.5vh;
    }
  }
  
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
    width: 50%;
  }
`