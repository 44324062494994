import React, { useEffect, useState, useMemo } from "react";
import "./home.css";
import { GlobalStyle } from "../../common/styles/global.js";

export const Home = () => {
  const words = useMemo(() => [
    "Software Engineer",
    "Coffee-Fueled",
    "Lifelong Learner",
    "Team Player",
    "Tinkerer",
  ], []);
  const [currentWord, setCurrentWord] = useState("");
  const [wordIndex, setWordIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (charIndex < words[wordIndex].length) {
        setCurrentWord((prev) => prev + words[wordIndex][charIndex]);
        setCharIndex((prev) => prev + 1);
      } else {
        clearInterval(typingInterval);
        const timeout = setTimeout(() => {
          setCharIndex(0);
          setCurrentWord("");
          setWordIndex((prev) => (prev + 1) % words.length);
        }, 1000);
        return () => clearTimeout(timeout);
      }
    }, 150);

    return () => clearInterval(typingInterval);
  }, [charIndex, wordIndex, words]);

  return (
    <>
      <GlobalStyle />
      <div className="main">
        <div id="mainbox">
          <div id="home___tool-bar">
            <div id="home___tool-bar-name">
              <div className="home___namebar-fill"></div>
              <div id="home___namebar">
                <a href="/" style={{ textDecoration: "none", color: "inherit" }}>
                  Marcellus
                </a>
              </div>
              <div className="home___namebar-fill"></div>
            </div>
            <div id="home___tool-bar-tiles">
              <div id="home___tool-bar___entries">
                <a
                  className="home___tool-bar-button"
                  href="https://github.com/c3llus"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">GitHub</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://www.linkedin.com/in/c3llus/"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">LinkedIn</p>
                  </div>
                </a>
                <a
                  className="home___tool-bar-button"
                  href="https://blog.c3llus.dev"
                >
                  <div className="home___tool-bar__entry">
                    <p className="home___tool-bar__entry-text">Blog</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="home__content">
            <div className="home__typewriter">
              <div className="output" id="output">
                <h1 className="cursor" style={{ marginTop: "0px", marginBottom: "0px" }}>
                  {currentWord}
                </h1>
              </div>
              <div id="home___text">
                <p className="mainbox-text">Hello world!</p>
                <p className="mainbox-text">
                  I'm Marcellus, a passionate software engineer with a strong focus on building impactful, scalable solutions.
                  With experience in systems programming, backend development, and a deep love for problem-solving,
                  I balance technical excellence with a user-first mindset. 
                  <br></br>
                  <br></br>
                  Let's build something great together!
                </p>
              </div>
            </div>
            {/* <br>placeholder1</br> */}
            
            <div id="home__links">
              <p className="home___link-text"><a href="/projects">Personal Projects</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
